.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.largeHeader{
  padding-left: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 0px;
  background-color: #0028A5;
  color: #fff9ef;
}


.footer{
  background-color: #0028A5;
  color: #fff9ef;
}

.text {
  padding-left: 40px;
  font-size: larger;
}

.taskDescription {
  padding-left: 40px;
  font-size: 14px;
}

.button{
  color: #282c34;
  border: None;
  padding-top: 7px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7px;
  border-radius: 3px;
  font-size: medium;
}

.wholeApp {
  background: white;
  position: relative;
  min-height: 100vh;
  top: 0;
  right:0;
  bottom:0;
  left: 0;
}

.goButton{
  background: green;
  color: #fff9ef;
}

.decisionButton1{
  background: #ec2c74;
}

.decisionButton2{
  background: #e6e425;
}

.confirmButton{
  background: #258fe6;
  color: #fff9ef;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

td {
  text-align: center;
}

.backgroundHeader1 {
  /* background: #ec2c74; */
  background: #50D7D5;
}

.backgroundHeader2 {
  background: #e6e425;
}

.backgroundCell1{
  /* background: #e99eae; */
  background: #A3D5D4;
}

.backgroundCell2{
  background: #fcfc97;
}
datalist {
  display: flex;
  justify-content: space-between;
  color: black;
  width: 900px;
  margin-left: 40px;
}

.slider{
  -webkit-appearance: none;
  background: #d3d3d3;
  outline: none;
  border-radius: 5px; 
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-left: 90px;
  align-items: center;
}

input {
  width: 800px;
}

#sliderOption {
max-width: 100px;
white-space: normal;
text-align: center;
}